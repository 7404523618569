import React from "react"
import layoutStyles from "../styles/components/layout.module.scss"
import legalStyles from "../styles/pages/legal.module.scss"
import Layout from "../components/Layout"
import useSiteMetadata from "../static_queries/useSiteMetadata"
import Header from "../components/Header"
import contactStyles from "../styles/components/contact.module.scss"
import { Link } from "gatsby"

export default function Privacybeleid(props) {
  const { title } = useSiteMetadata()
  const date = new Date()
  const year = date.getFullYear()
  return (
    <Layout>
      <Header page={props.page} title={title} />
      <section className={layoutStyles.content}>
        <div className={legalStyles.legal}>
          <div className="title-main title-policy">
            <h1>Privacy Policy</h1>
          </div>
          <main className="policy">
            <div>
              <p>
                Your privacy is important to us. It is Van Laer BV's policy to
                respect your privacy regarding any information we may collect
                from you across our website,{" "}
                <a href="http://ashleyvanlaaer.be">http://ashleyvanlaaer.be</a>,
                and other sites we own and operate.
              </p>
              <div>
            <p><strong>Who processes the Personal Data?</strong></p>
            {/* <p>
            Van Laer BV is responsible for the use of the website. The
              website is owned by:
            </p>
            <p>
              Van Laer BV
              <br />
              Keulsebaan 29,
              <br />
              2243 Pulle
            </p> */}
            <p>
              <br />
              {/* Mobile: <a href="tel:+32477895991">+32 (0)477 89 59 91</a> */}
              <br />
              Email: <a href="mailto:info@ashleyvanlaer.be">info@ashleyvanlaer.be</a>
              <br />
              Website: <a href="https://ashleyvanlaer.be">https://ashleyvanlaer.be/</a>
            </p>
            {/* <p>
           Van Laer BV is responsible for processing ('controller') and determines
              alone or in cooperation with others, the purpose and means for
              the processing of your personal data. This always in the context of
              of the use of the website and its functionalities.
            </p> */}
          </div>
              <p>
                We only ask for personal information when we truly need it to
                provide a service to you. We collect it by fair and lawful
                means, with your knowledge and consent. We also let you know why
                we’re collecting it and how it will be used.
              </p>
              <p>
                We only retain collected information for as long as necessary to
                provide you with your requested service. What data we store,
                we’ll protect within commercially acceptable means to prevent
                loss and theft, as well as unauthorized access, disclosure,
                copying, use or modification.
              </p>
              <p>
                We don’t share any personally identifying information publicly
                or with third-parties, except when required to by law.
              </p>
              <p>
                Our website may link to external sites that are not operated by
                us. Please be aware that we have no control over the content and
                practices of these sites, and cannot accept responsibility or
                liability for their respective privacy policies.
              </p>
              <p>
                You are free to refuse our request for your personal
                information, with the understanding that we may be unable to
                provide you with some of your desired services.
              </p>
              <p>
                Your continued use of our website will be regarded as acceptance
                of our practices around privacy and personal information. If you
                have any questions about how we handle user data and personal
                information, feel free to contact us.
              </p>
              <p>This policy is effective as of 9 October 2020.</p>
            </div>
          </main>
        </div>
        <p className={contactStyles.copyright}>
        ©{year} Digital North BV • BE0798.932.679 • All rights reserved -
        <Link to="/cookie-policy"> Cookie policy </Link>-
        <Link to="/privacy-policy"> Privacy policy </Link>
      </p>
      </section>
    </Layout>
  )
}
